<template>
  <section id="home">
    <HomeHero />
    <!-- <LegacyHomeActivities /> -->
    <section class="height-spacer">
      <div class="width-spacer">
        <HomeCategories />
      </div>
    </section>
    <HomeBlog2
      title-end="donner de mon temps"
      :article-data="article1"
    />
    <section class="height-spacer height-spacer-Oblong">
      <div class="width-spacer">
        <HomeHowItWorks />
      </div>
    </section>
    <HomeBlog2
      title-end="des histoires de liens qui font simplement du bien"
      :article-data="article2"
    />
    <section class="height-spacer">
      <div class="width-spacer">
        <HomeCities />
      </div>
    </section>
    <section class="height-spacer">
      <div class="width-spacer">
        <HomeExperiences />
      </div>
    </section>
    <section class="height-spacer">
      <div class="width-spacer">
        <HomeNumbers />
      </div>
    </section>
    <HomeSeoMeshing />
  </section>
</template>

<script setup lang="ts">
import {
  useServerSeoMeta,
  onMounted,
  useJsonld,
  useHead
} from '#imports'
import useTracking from '@ha/components-v3/src/composables/useTracking'
import HomeHero from '../components/HomeHero.vue'
import HomeCategories from '../components/HomeCategories.vue'
import HomeHowItWorks from '@/components/HowItWorks/HowItWorks.vue'
import HomeCities from '../components/HomeCities.vue'
import HomeExperiences from '../components/HomeExperiences.vue'
import HomeNumbers from '../components/HomeNumbers.vue'
import HomeSeoMeshing from '../components/HomeSeoMeshing.vue'
import useConfigEnv from '@/composables/useConfigEnv'
// import LegacyHomeActivities from '@/domains/home/components/HomeActivities/LegacyHomeActivities.vue'
import HomeBlog2 from '../components/HomeBlog2.vue'
import { fetchBlogArticles } from '@/services/fetchBlogArticles.service'

const [article1, article2] = fetchBlogArticles()

const configEnv = useConfigEnv()
const { tracking } = useTracking()

useHead({
  link: [{ rel: 'canonical', href: `${configEnv.NUXT_ENV_BASE_URL}` }]
})

useServerSeoMeta({
  title: 'HelloAsso : Trouvez et soutenez les projets associatifs',
  ogTitle: 'HelloAsso : Trouvez et soutenez les projets associatifs',
  ogDescription:
    'HelloAsso, le lien entre +350 000 associations et vous ! Trouvez des projets inspirants, participez à des événements et soutenez les associations',
  ogUrl: 'https://www.helloasso.com/',
  twitterTitle:
    'HelloAsso : Trouvez et soutenez les projets associatifs',
  twitterDescription:
    'HelloAsso, le lien entre +350 000 associations et vous ! Trouvez des projets inspirants, participez à des événements et soutenez les associations'
})

onMounted(() => {
  tracking.page({
    rubrik: 'Explore',
    context: 'Contributeur',
    category: 'Home',
    version: configEnv.PACKAGE_VERSION
  })
})

useJsonld({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  legalName: 'HelloAsso',
  description:
    'Que l’on organise un événement, que l’on accueille de nouveaux membres, que l’on soit à la recherche de soutiens financiers ou que l’on souhaite tout simplement encaisser un paiement, nos outils gratuits et sécurisés permettent de gagner un temps précieux dans la gestion de son association',
  logo: 'https://www.helloasso.com/assets/img/logos/logo-helloasso-midnight.svg',
  url: 'https://www.helloasso.com',
  sameAs: [
    'https://www.facebook.com/helloasso',
    'https://twitter.com/helloasso',
    'https://www.youtube.com/channel/UCYz5xk40Yayisba7qTjHAdQ'
  ],
  contactPoint: [
    {
      '@type': 'ContactPoint',
      contactType: 'customer service',
      telephone: '+33564880234'
    }
  ],
  address: {
    '@type': 'PostalAddress',
    streetAddress: '2 rue Marc Sangnier',
    addressLocality: 'Bègles',
    addressRegion: 'Nouvelle-Aquitaine',
    postalCode: '33130'
  }
})
</script>

<style lang="scss">
#home {
  background: white;

  img {
    pointer-events: none; // Used to disable visual search buttons on MS-Edge.
  }

  .Cities,
  .Experiences,
  .Edito {
    figure {
      overflow: hidden;
      border-radius: 8px;

      img {
        transition: 0.15s ease-out;
      }
    }

    a:hover {
      figure img {
        transform: scale(1.1);
      }
    }
  }

  .Cities,
  .Experiences {
    a {
      h4 {
        color: var(--ha-color-text);
      }

      figcaption {
        color: var(--ha-color-white);
      }

      &:hover {
        h4 {
          color: var(--ha-color-primary);
        }

        figure .Highlighted::before {
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    figure {
      position: relative;

      &::after {
        content: '';
        background: rgb(0 0 0 / 10%);
        position: absolute;
        width: 100%;
        height: 100%;
      }

      &.Highlighted {
        background: none;
        position: relative;
        z-index: 1;
        display: grid;
        place-items: center;
        padding: 0.25em 1em;

        &::before {
          content: '';
          position: absolute;
          top: 1em;
          z-index: -1;
          background: var(--hads-color-mint);
          border-radius: 10em;
          display: block;
          height: 30%;
          width: 80%;
          transition: 0.2s ease-out;
        }

        &-Retrowave::before {
          background: var(--hads-color-retrowave);
        }

        &-Desert::before {
          background: var(--hads-color-desert);
        }

        &-Iris::before {
          background: var(--hads-color-iris);
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
#home {
  .widthSlider {
    @include mediaQuery(900, 'max') {
      padding-left: 2em;
    }
  }

  .height-spacer-Oblong {
    @include mediaQuery(900) {
      // Oblongs are hidden under this size in HowItWorks section.
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: block;
        width: calc(100% - 1em);
        height: 100%;
        top: 0;
        background: #f8fafd;
        z-index: -1;
        border-radius: 0 100vw 100vw 0;
      }
    }
  }
}
</style>
